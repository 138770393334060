export const enum SectionIdEnum{
    intro = "Home",
    about = "Wat is karate",
    aboutUs = "Over ons",
    whyUs = "Waarom ons",
    quickNav = "Handige links",
    trainer = "trainers",
    skills = "skills",
    projects = "projects",
    contact = "contact",
}