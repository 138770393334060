import React from "react"
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {logoImg} from "../../assets";
import {SectionIdEnum} from "../../types";
import {Footer} from "./footer/footer";
import {useTranslation} from "react-i18next";

export type AppLayoutProps = {
    isSmall: boolean,
    navigation?: React.ReactNode,
    children: React.ReactNode
}

export const AppLayout: React.FC<AppLayoutProps> = ({navigation, children, isSmall}) => {
    const {t} = useTranslation();

    return <Box height="100vh" className={"app-layout"}>
        <AppBar position={"fixed"} sx={{background: '#24263b'}}>
            <Toolbar>
                <Box flexGrow={1}>
                    <AnchorLink href={`#${SectionIdEnum.intro}`} offset={isSmall ? "56px" : "64px"}
                                className={"all_unset"}>
                        <Box display={"flex"} alignItems={"center"} gap={.5} sx={{cursor: "pointer"}}>
                            <img height={"54px"} src={logoImg} alt={"Dojomierlo"}/>
                            <Typography variant={"h5"} sx={{width: 'fit-content'}}>{t('layout.app-name')}</Typography>
                        </Box>
                    </AnchorLink>
                </Box>
                {navigation}
            </Toolbar>
        </AppBar>
        <Box minHeight={"100vh"}>
            <Toolbar/>
            {children}
        </Box>
        <Footer/>
    </Box>
}