import logo from "./logo.svg";

import slider_img_1 from "./slider/f22c95ae-dcf1-4874-aa88-385965d1d966.jpg"
import slider_img_2 from "./slider/S1680016.jpg";
import slider_img_3 from "./slider/slider_DMG_stagelecourt_zeist_2019.jpg";
import slider_img_4 from "./slider/slider_S1150098_trainers.jpg";
import slider_img_5 from "./slider/Slider_DSC08841.jpg";
import slider_img_6 from "./slider/Slider_DSC08853.jpg";

import lamp from "./quicknav/lamp.jpg";
import documents from "./quicknav/documents.png";
import megaphone from "./quicknav/megaphone.png";

import whyUs from "./why-us/image1.jpg";

import cees from "./trainers/PHOTO-2022Cees.jpg";
import gerard from "./trainers/PHOTO-2022Gerard.jpg";
import jense from "./trainers/PHOTO-2022Jense.jpg";
import luc from "./trainers/PHOTO-2022Luc.jpg";
import manon from "./trainers/PHOTO-2022Manon.jpg";
import tim from "./trainers/PHOTO-2022Tim.jpg";

export const logoImg = logo;

export const sliderImg1 = slider_img_1;
export const sliderImg2 = slider_img_2;
export const sliderImg3 = slider_img_3;
export const sliderImg4 = slider_img_4;
export const sliderImg5 = slider_img_5;
export const sliderImg6 = slider_img_6;

export const lampImg = lamp;
export const documentsImg = documents;
export const megaphoneImg = megaphone;

export const whyUsImg = whyUs;

export const ceesImg = cees;
export const gerardImg = gerard;
export const jenseImg = jense;
export const lucImg = luc;
export const manonImg = manon;
export const timImg = tim;