import React, {useState} from "react";
import {SectionIdEnum} from "../../../types";
import {
    AppBar,
    Box,
    Button,
    Dialog,
    Hidden,
    IconButton, Slide,
    Toolbar,
    Typography
} from "@mui/material";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {Close, Menu} from "@mui/icons-material";
import {TransitionProps} from "@mui/material/transitions";
import "./app-layout.css";

export type NavigationProps = {
    isSmall: boolean,
}

const navigationItems = [
    {
        text: "Over ons",
        to: SectionIdEnum.about,
    },
    {
        text: "Algemene informatie",
        to: SectionIdEnum.quickNav,
    },
    {
        text: "Trainers",
        to: SectionIdEnum.trainer,
    },
    {
        text: "Contact",
        to: SectionIdEnum.contact,
    },
];

const Transition = React.forwardRef((
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) => {
    return <Slide direction={"left"} ref={ref} {...props}/>
})


export const Navigation: React.FC<NavigationProps> = ({isSmall}) => {
    const [open, setOpen] = useState(false);

    const onOpenHandler = () => setOpen(true);
    const onCloseHandler = () => setOpen(false);
    const mappedItems = (
        navigationItems.map(({to, text}) => {
            return (
                <AnchorLink key={to} href={`#${to}`} offset={isSmall ? "56px" : "64px"}
                            className={"all_unset nav_button"}>
                    <Button color={"inherit"} size={"large"} fullWidth={isSmall} onClick={onCloseHandler}>
                        {text}
                    </Button>
                </AnchorLink>
            )
        })
    )

    return (
        <>
            <Hidden smDown>
                <Box display={"flex"} gap={2}>
                    {mappedItems}
                </Box>
            </Hidden>
            <Hidden smUp>
                <IconButton className={"nav-button"} color={"inherit"} onClick={onOpenHandler}>
                    <Menu/>
                </IconButton>
                <Dialog open={open}
                        fullScreen
                        fullWidth
                        TransitionComponent={Transition}
                        hideBackdrop
                        PaperProps={{
                            sx: {
                                boxShadow: "none"
                            }
                        }}
                >
                    <AppBar position={"static"} sx={{background: "#24263b", color: "#FFFFFF"}}>
                        <Toolbar>
                            <Typography variant={"h5"} sx={{flexGrow: 1}}>
                                Menu
                            </Typography>
                            <IconButton className={"nav-button"} onClick={onCloseHandler}>
                                <Close sx={{color: "#FFFFFF"}}/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Box display={"flex"} flexDirection={"column"} py={3} width={"100%"} height={"100%"} color={"#FFFFFF"} sx={{backgroundColor:"rgb(36, 38, 59, .9)"}}>
                        {mappedItems}
                    </Box>
                </Dialog>
            </Hidden>
        </>
    )
}