import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {fetchTrainers, Trainer} from './trainerAPI';

export interface TrainerState {
    trainers: Trainer[];
    status: 'idle' | 'loading' | 'failed';
}

const initialState: TrainerState = {
    trainers: [],
    status: 'idle',
};

export const fetchTrainersAsync = createAsyncThunk(
    'trainer/fetchTrainers',
    async () => await fetchTrainers()
);

export const trainerSlice = createSlice({
    name: 'trainer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTrainersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTrainersAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.trainers = action.payload;
            })
            .addCase(fetchTrainersAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTrainers = (state: RootState) => state.trainer.trainers;

export default trainerSlice.reducer;
