import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchArticlesAsync, selectArticles} from "./articleSlice";
import {useMediaQuery, useTheme} from "@mui/material";
import {AppLayout} from "../../components";
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css"
import {Article} from "./articleAPI";
import {Link} from "react-router-dom";
import "./article.css";

export const ArticlePage: React.FC = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const articles = useAppSelector(selectArticles);
    const dispatch = useAppDispatch();

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    useEffect(() => {
        dispatch(fetchArticlesAsync());
    }, [dispatch])

    const listItem = (article: Article) => {
        return (
            <VerticalTimelineElement
                key={article.title}
                className="vertical-timeline-element--work"
                date={(new Date(article.publishingDate)).toDateString()}
                iconStyle={
                    article.type === 'STAGEVERSLAG' ?
                        {background: 'rgb(173,23,77)', color: '#fff'}
                        : article.type === 'ARTICLE' ?
                            {background: '#016e8d', color: '#fff'}
                            : {background: '#5e018d', color: '#fff'}
                }
            >
                <h3 className="vertical-timeline-element-title">{article.title}</h3>
                {
                    article.src && (
                        <div className="row">
                            <div className="col-12">
                                <img src={article.src} alt={article.title}/>
                            </div>
                        </div>
                    )
                }
                {article.description && <p>{article.description}</p>}

                <div className="row">
                    <div className="col-12">
                        <div className="more">
                            <Link to={article.url} className="btn btn-primary">
                                Lees meer
                                <i className="right chevron icon"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </VerticalTimelineElement>
        );
    }

    return (
        <AppLayout isSmall={isSmall}>
            <div className={"article"}>
                <VerticalTimeline>
                    {articles.map((article: Article) => listItem(article))}
                    <VerticalTimelineElement
                        iconStyle={{background: '#018d18', color: '#fff'}}
                    />
                </VerticalTimeline>
            </div>
        </AppLayout>
    );
}