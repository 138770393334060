import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchContentAsync, selectContentPages} from "./contentSlice";
import DOMPurify from "dompurify";
import {Helmet} from "react-helmet";
import {Container, useMediaQuery, useTheme} from "@mui/material";
import {AppLayout} from "../../components";
import 'bootstrap/dist/css/bootstrap.min.css';

export const ContentPage: React.FC = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const contents = useAppSelector(selectContentPages);
    const dispatch = useAppDispatch();

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    useEffect(() => {
        dispatch(fetchContentAsync(window.location.pathname));
    }, [dispatch])

    const index = contents.findIndex(object => object.url === window.location.pathname);

    if (index === -1) {
        return <AppLayout isSmall={isSmall}><Container></Container></AppLayout>;
    }

    const page = contents[index];
    const sanitizer = DOMPurify.sanitize;

    return (
        <AppLayout isSmall={isSmall}>
            <Container>
                <Helmet>
                    <title>{page.title}</title>
                    <meta name='description' content={sanitizer(page.data)}/>
                </Helmet>
                <main>
                    <div className="container page">
                        <h1>{page.title}</h1>
                        <div dangerouslySetInnerHTML={{__html: sanitizer(page.data)}}/>
                    </div>
                </main>
            </Container>
        </AppLayout>
    );
}