import React, {ElementType} from "react";
import { ButtonProps, Button } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";



const LinkButton = <C extends ElementType>(
    props: ButtonProps<C, { component?: C }>
) => {
    return <Button {...props} component={ReactRouterLink} to={props.href ?? "#"} />;
};

export default LinkButton;