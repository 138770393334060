import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {fetchArticles, fetchArticle, Article} from './articleAPI';

export type ArticleState = {
    articles: Article[];
    status: 'idle' | 'loading' | 'failed';
}

const initialState: ArticleState = {
    articles: [],
    status: 'idle',
};

export const fetchArticlesAsync = createAsyncThunk(
    'article/fetchArticles',
    async () => await fetchArticles()
);

export const fetchArticleAsync = createAsyncThunk(
    'article/fetchArticle',
    async (slug: string) => await fetchArticle(slug)
);

export const articleSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchArticlesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchArticlesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.articles = action.payload;
            })
            .addCase(fetchArticlesAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchArticleAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchArticleAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload !== undefined) {
                    const index = state.articles.findIndex(object => object.url === action.payload?.url);

                    if (index === -1) {
                        state.articles = [...state.articles, action.payload];
                    } else {
                        state.articles[index] = action.payload;
                    }
                }
            })
            .addCase(fetchArticleAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectArticles = (state: RootState) => state.article.articles;

export default articleSlice.reducer;
