import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {fetchContents, fetchContent, ContentPage} from './contentAPI';

export interface ContentState {
    contents: ContentPage[];
    status: 'idle' | 'loading' | 'failed';
}

const initialState: ContentState = {
    contents: [],
    status: 'idle',
};

export const fetchContentsAsync = createAsyncThunk(
    'content/fetchContents',
    async () => await fetchContents()
);

export const fetchContentAsync = createAsyncThunk(
    'content/fetchContent',
    async (slug: string) => await fetchContent(slug)
);

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchContentsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchContentsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.contents = action.payload;
            })
            .addCase(fetchContentsAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchContentAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchContentAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload !== undefined) {
                    const index = state.contents.findIndex(object => object.url === action.payload?.url);

                    if (index === -1) {
                        state.contents = [...state.contents, action.payload];
                    } else {
                        state.contents[index] = action.payload;
                    }
                }
            })
            .addCase(fetchContentAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectContentPages = (state: RootState) => state.content.contents;

export default contentSlice.reducer;
