import React from "react";
import {Container, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import "./about.css";
import {ChevronRight} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import LinkButton from "../../components/link-button/link-button";

export const AboutSection = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const {t} = useTranslation();

    return (
        <Container className={"about"}>
            <Grid container spacing={2}>
                <Grid item sm={12} className={"content"}>
                    <Typography variant={"h4"} className={"title"}>{t("about.title")}</Typography>
                </Grid>
                <Grid item sm={12} className={"content"}>
                    <Typography variant={"body1"} style={isSmall ? {
                        alignContent: "left",
                        textAlign: "left"
                    } : {
                        alignContent: "center",
                        textAlign: "center"
                    }}
                    >{t("about.description")}</Typography>
                </Grid>
                <Grid item sm={12} className={"content"}>
                    <div className="extra">
                        <LinkButton className={"btn-light"} variant="contained" endIcon={<ChevronRight/>} href={"wat-is-karate"}>
                            {t("about.button")}
                        </LinkButton>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};
