import React from "react";
import {whyUsImg} from "../../assets";
import {Box, Grid, Hidden, Typography} from "@mui/material";
import {Check} from "@mui/icons-material";
import "./why-us.css";
import {useTranslation} from "react-i18next";

export const WhyUsSection = () => {
    const {t} = useTranslation();
    const bulletPoints = [
        t("why-us.bulletPoints.point1"),
        t("why-us.bulletPoints.point2"),
        t("why-us.bulletPoints.point3"),
        t("why-us.bulletPoints.point4"),
        t("why-us.bulletPoints.point5"),
        t("why-us.bulletPoints.point6")
    ];

    const content = (
        <>
            <Grid item xs={12} style={{padding: "16px"}}>
                <Typography variant={"h4"}>{t("why-us.title")}</Typography>
            </Grid>
            <Grid item xs={12} style={{padding: "16px"}}>
                {bulletPoints.map(value => (
                    <Typography variant={"h5"} style={{margin: "12px 0"}} key={value}>
                        <Check/> {value}
                    </Typography>
                ))}
            </Grid>
        </>
    )

    return (
        <Grid container className={"why-us"}>
            <Hidden smDown>
                <Grid item xs={5}>
                    <Box style={{
                        background: `url(${whyUsImg})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "100%",
                        width: "100%"
                    }}>
                    </Box>
                </Grid>
                <Grid item xs={7} style={{padding: "16px"}}>
                    <Grid container>
                        {content}
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden smUp>
                {content}
            </Hidden>
        </Grid>
    );
}
