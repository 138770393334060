import React, {useRef} from "react";
import 'react-slideshow-image/dist/styles.css'
import {Slide, SlideshowRef} from "react-slideshow-image";
import {sliderImg1, sliderImg4, sliderImg6} from "../../assets";
import "./intro.css";
import {Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import LinkButton from "../../components/link-button/link-button";

export const IntroSection: React.FC = () => {
    const {t} = useTranslation();
    const slideImages = [
        {
            url: sliderImg6,
            title: t("intro.slide1.title"),
            description: t("intro.slide1.description"),
            href: t("intro.slide1.href"),
            value: t("intro.slide1.button"),
        },
        {
            url: sliderImg1,
            title: t("intro.slide2.title"),
            description: t("intro.slide2.description"),
            href: t("intro.slide2.href"),
            value: t("intro.slide2.button"),
        },
        {
            url: sliderImg4,
            title: t("intro.slide3.title"),
            description: t("intro.slide3.description"),
            href: t("intro.slide3.href"),
            value: t("intro.slide3.button"),
        },
    ];

    const slideRef = useRef<SlideshowRef>(null)
    return (
        <>
            <Slide indicators={true} ref={slideRef}>
                {slideImages.map(({url, title, description, value, href}) => (
                    <div className={"slide"} key={title}>
                        <div key={title}
                             className={"slider-content"}
                             style={{
                                 textAlign: 'center',
                                 background: `url(${url})`,
                                 backgroundPosition: "center",
                                 backgroundRepeat: "no-repeat",
                                 backgroundSize: "cover",
                                 objectFit: "contain",
                                 padding: '200px 0',
                                 fontSize: '30px'
                             }}
                        >
                            <div className="inner" style={{zIndex: 9999}}>
                                <div className={"title"}>
                                    <Typography variant={"h4"}>{title}</Typography>
                                </div>
                                <div className={"description"}>
                                    <Typography variant={"body1"}>{description}</Typography>
                                </div>
                            {
                                (href &&
                                    <LinkButton className={"btn-primary"} variant="contained" endIcon={<ChevronRight/>}
                                            href={href}>
                                        {value}
                                    </LinkButton>
                                )
                            }
                            </div>
                        </div>
                    </div>
                ))}
            </Slide>
        </>
    );
};