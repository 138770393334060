import React, {useEffect} from "react";
import {Container, Grid, Hidden, Typography} from "@mui/material";
import "./trainer.css";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchTrainersAsync, selectTrainers} from "./trainerSlice";
import {useTranslation} from "react-i18next";

export const TrainerSection = () => {
    const {t} = useTranslation();
    const trainers = useAppSelector(selectTrainers);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchTrainersAsync());
    }, [dispatch])

    const sectionImages = trainers.map(({src, name}) => (
        <Grid item sm={2} key={src} className={"content"}>
            <img src={src} alt={name}/>
        </Grid>
    ));
    const sectionNames = trainers.map(({name}) => (
        <Grid item sm={2} key={name} className={"content"}>
            <Typography variant={"body1"}>{name}</Typography>
        </Grid>
    ));

    const sectionGrades = trainers.map(({grade, id}) => (
        <Grid item sm={2} key={id} className={"content"}>
            <Typography variant={"body1"}>{grade}</Typography>
        </Grid>
    ));

    const sectionMemberships = trainers.map(({
                                                                                   membershipName,
                                                                                   membershipLink,
                                                                                   id
                                                                               }) => (
        <Grid item xs={2} key={membershipName + id} className={"content"}>
            <div className="extra">
                <Typography variant={"body1"}>
                    <Link to={membershipLink} style={{color: "#ff6600"}}>
                        {membershipName}
                    </Link>
                </Typography>
            </div>
        </Grid>
    ));

    const sectionMobile = trainers.map(({
                                                                              src,
                                                                              name,
                                                                              grade,
                                                                              membershipName,
                                                                              membershipLink
                                                                          }) => (
        <Grid item sm={12} key={src} className={"content"}>
            <Grid item sm={12} className={"content"}>
                <img src={src} alt={name}/>
            </Grid>
            <Grid item sm={12} className={"content"}>
                <Typography variant={"body1"}>{name}</Typography>
            </Grid>
            <Grid item sm={12} className={"content"}>
                <Typography variant={"body1"}>{grade}</Typography>
            </Grid>
            <Grid item xs={12} className={"content"}>
                <div className="extra">
                    <Typography variant={"body1"}>
                        <Link to={membershipLink} style={{color: "#ff6600"}}>
                            {membershipName}
                        </Link>
                    </Typography>
                </div>
            </Grid>
        </Grid>
    ));
    return (
        <div style={{backgroundColor: "#f5f5f5"}}>
            <Container className={"trainer"}>
                <Grid container spacing={2}>
                    <Grid item sm={12} className={"content"}>
                        <Typography variant={"h4"} className={"title"}>{t("trainer.title")}</Typography>
                    </Grid>
                    <Hidden smDown>
                        {sectionImages}
                        {sectionNames}
                        {sectionGrades}
                        {sectionMemberships}
                    </Hidden>
                    <Hidden smUp>
                        {sectionMobile}
                    </Hidden>
                    <Grid item sm={12}>
                        <Typography variant={"body1"}>{t("trainer.description")}</Typography>
                    </Grid>

                </Grid>
            </Container>
        </div>
    );
};
