import React from "react";
import {Container, Grid, Hidden, Typography} from "@mui/material";
import {documentsImg, lampImg, megaphoneImg} from "../../assets";
import "./quicknav.css";
import {ChevronRight} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import LinkButton from "../../components/link-button/link-button";

export const QuickNavSection = () => {
    const {t} = useTranslation();

    const quickNavs = [
        {
            image: megaphoneImg,
            title: t("quick-nav.training.title"),
            description: t("quick-nav.training.description"),
            value: t("quick-nav.training.button"),
            href: t("quick-nav.training.href"),
        },
        {
            image: lampImg,
            title: t("quick-nav.starters.title"),
            description: t("quick-nav.starters.description"),
            value: t("quick-nav.starters.button"),
            href: t("quick-nav.starters.href"),
        },
        {
            image: documentsImg,
            title: t("quick-nav.reports.title"),
            description: t("quick-nav.reports.description"),
            value: t("quick-nav.reports.button"),
            href: t("quick-nav.reports.href"),
        },
    ];

    const sectionImages = quickNavs.map(({image}) => (
        <Grid item sm={4} key={image} className={"content"}>
            <img src={image} alt={image}/>
        </Grid>
    ));
    const sectionTitles = quickNavs.map(({title}) => (
        <Grid item sm={4} key={title} className={"content"}>
            <Typography variant={"h6"}>{title}</Typography>
        </Grid>
    ));

    const sectionDescriptions = quickNavs.map(({description}) => (
        <Grid item sm={4} key={description} className={"content"}>
            <Typography variant={"body1"}>{description}</Typography>
        </Grid>
    ));

    const sectionButtons = quickNavs.map(({value, href}) => (
        <Grid item xs={4} key={href} className={"content"}>
            <div className="extra">
                <LinkButton className={"btn-light"} variant="contained" endIcon={<ChevronRight/>} href={href}>
                    {value}
                </LinkButton>
            </div>
        </Grid>
    ));

    const sectionMobile = quickNavs.map(({image, title, description, value, href}) => (
        <Grid item sm={4} key={image} className={"content"}>
            <img src={image} alt={image}/>
            <Typography variant={"h5"}>{title}</Typography>
            <Typography variant={"body1"}>{description}</Typography>
            <div className="extra">
                <LinkButton className={"btn-light"} variant="contained" endIcon={<ChevronRight/>} href={href}>
                    {value}
                </LinkButton>
            </div>
        </Grid>
    ));
    return (
        <Container className={"quick-nav"}>
            <Hidden smDown>
                <Grid container spacing={2}>{sectionImages}</Grid>
                <Grid container spacing={2}>{sectionTitles}</Grid>
                <Grid container spacing={2}>{sectionDescriptions}</Grid>
                <Grid container spacing={2}>{sectionButtons}</Grid>
            </Hidden>
            <Hidden smUp>
                <Grid container spacing={2}>{sectionMobile}</Grid>
            </Hidden>
        </Container>
    );
};
