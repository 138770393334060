import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {HomePage} from "./templates";
import {ContentPage} from "./templates";
import {ArticlePage} from "./templates/article/article.page";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />}/>
                {/*<Route path="/stageverslagen" exact component={Reports}/>*/}
                <Route path="/stageverslagen" element={<ArticlePage />}/>
                <Route path="/:slug" element={<ContentPage />}/>
            </Routes>
        </Router>
    );
}

export default App;