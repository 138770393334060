import React from "react";
import {GoogleMap, LoadScript, MarkerF} from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "100%"
};

const center = {
    lat: 51.4373,
    lng: 5.6202
};

export const Map: React.FC = () => {
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyA-J22ZSOjvaUQou4PVyV7LAi4LFAMfg6w"
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={14}
            >
                { /* Child components, such as markers, info windows, etc. */}
                <MarkerF position={center}/>
            </GoogleMap>
        </LoadScript>
    )
}