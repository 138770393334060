import React from "react";
import {Container, Grid, Hidden, Typography} from "@mui/material";
import "./about-us.css";
import {useTranslation} from "react-i18next";

export const AboutUsSection = () => {
    const {t} = useTranslation();

    const aboutUs = [
        {
            title: t("about-us.school.title"),
            description: t("about-us.school.description"),
        },
        {
            title: t("about-us.style.title"),
            description: t("about-us.style.description"),
        }
    ];

    const sectionTitles = aboutUs.map(({title}) => (
        <Grid item sm={6} key={title} className={"content"}>
            <Typography variant={"h4"} className={"title"}>{title}</Typography>
        </Grid>
    ));

    const sectionDescriptions = aboutUs.map(({description}) => (
        <Grid item sm={6} key={description} className={"content"}>
            <Typography variant={"body1"}>{description}</Typography>
        </Grid>
    ));

    const sectionMobile = aboutUs.map(({title, description}) => (
        <Grid item sm={4} key={title} className={"content"}>
            <Typography variant={"h4"} className={"title"}>{title}</Typography>
            <Typography variant={"h6"}>{description}</Typography>
        </Grid>
    ));
    return (
        <div style={{backgroundColor: "#f5f5f5"}}>
            <Container className={"about-us"}>
                <Hidden smDown>
                    <Grid container spacing={2}>{sectionTitles}</Grid>
                    <Grid container spacing={2}>{sectionDescriptions}</Grid>
                </Hidden>
                <Hidden smUp>
                    <Grid container spacing={2}>{sectionMobile}</Grid>
                </Hidden>
            </Container>
        </div>
    );
};
