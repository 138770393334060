import React from "react";
import "./footer.css";
import {logoImg} from "../../../assets";
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";

export const Footer: React.FC = () => {
    const {t} = useTranslation();
    return (
        <footer className="footer">
            <Grid container>
                <Grid item xs={12}>
                    <div className="footer-logo">
                        <img src={logoImg} alt={"Dojomierlo"}/>
                    </div>
                </Grid>
                <Grid item xs={12} className={"footer-info-copy"} style={{margin: "auto", textAlign: "center"}}>
                    {'\u00A9'} {t("layout.copy")}
                </Grid>
            </Grid>
        </footer>
    );
};
