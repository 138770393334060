import React from "react";
import "./contact.css";
import {Grid, Hidden, Typography} from "@mui/material";
import {Map} from "../../components"
import {Mail, Person, Phone, Room} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export const ContactSection: React.FC = () => {
    const {t} = useTranslation();

    const contactInfo = (
        <div className="contact-info">
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={"h4"}>{t("contact.title")}</Typography>
                </Grid>
                <Grid item xs={1}><Person/></Grid>
                <Grid item xs={11}><Typography variant={"body1"}>{t("contact.contact")}</Typography></Grid>
                <Grid item xs={1}><Mail/></Grid>
                <Grid item xs={11}><Typography variant={"body1"}>{t("contact.mail")}</Typography></Grid>
                <Grid item xs={1}><Phone/></Grid>
                <Grid item xs={11}><Typography variant={"body1"}>{t("contact.tel")}</Typography></Grid>
                <Grid item xs={1}><Room/></Grid>
                <Grid item xs={11}><Typography variant={"body1"}>{t("contact.addressLine")}</Typography></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}><Typography variant={"body1"}>{t("contact.addressLine2")}</Typography></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}><Typography variant={"body1"}>{t("contact.addressLine3")}</Typography></Grid>
            </Grid>
        </div>
    );

    return (
        <div className="contact">
            <Grid container>
                <Hidden smDown>
                    <Grid item xs={6}>
                        <Map/>
                    </Grid>
                    <Grid item xs={6}>
                        {contactInfo}
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Grid item xs={12}>
                        {contactInfo}
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
}

