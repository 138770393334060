// A mock function to mimic making an async request for data
import {ceesImg, gerardImg, jenseImg, lucImg, manonImg, timImg} from "../../assets";

export type Trainer = {
    position: number,
    membershipName: string,
    membershipLink: string,
    grade: string,
    src: any,
    name: string,
    id: number
};

const compare = (a: Trainer, b: Trainer) => {
    if (a.position < b.position) {
        return -1;
    }
    if (a.position > b.position) {
        return 1;
    }
    return 0;
}

export function fetchTrainers(amount = 1) {
    return new Promise<Trainer[]>((resolve) =>
        setTimeout(() => resolve([{
            position: 6,
            membershipName: "",
            membershipLink: "",
            grade: "1e Dan",
            src: lucImg,
            name: "Luc Bollen",
            id: 6
        }, {
            position: 1,
            membershipName: "KSKA member",
            membershipLink: "/wat-is-karate",
            grade: "4e Dan KSKA",
            src: gerardImg,
            name: "Gerard Schouten",
            id: 1
        }, {
            position: 2,
            membershipName: "KSKA member",
            membershipLink: "/wat-is-karate",
            grade: "3e Dan JKA",
            src: ceesImg,
            name: "Cees van Schaaik",
            id: 2
        }, {
            position: 3,
            membershipName: "",
            membershipLink: "",
            grade: "1e Dan JKA",
            src: manonImg,
            name: "Manon Schouten",
            id: 3
        }, {
            position: 4,
            membershipName: "",
            membershipLink: "",
            grade: "1e Dan KSKA",
            src: timImg,
            name: "Tim van Oorschot",
            id: 4
        }, {
            position: 5,
            membershipName: "",
            membershipLink: "",
            grade: "1e Dan",
            src: jenseImg,
            name: "Jense Schouten",
            id: 5
        }].sort(compare)), 500)
    );
}
