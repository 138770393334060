import React from "react";
import {AppLayout, SectionContainer} from "../../components";
import {SectionIdEnum} from "../../types";
import {
    AboutSection,
    AboutUsSection,
    ContactSection,
    IntroSection,
    QuickNavSection,
    WhyUsSection
} from "../../features";
import {TrainerSection} from "../../features/trainer/trainer.section";
import {useMediaQuery, useTheme} from "@mui/material";
import {Navigation} from "../../components/app-layout/navigation/navigation.component";

const sections = [
    {
        sectionId: SectionIdEnum.intro,
        component: <IntroSection/>
    },
    {
        sectionId: SectionIdEnum.about,
        component: <AboutSection/>
    },
    {
        sectionId: SectionIdEnum.aboutUs,
        component: <AboutUsSection/>
    },
    {
        sectionId: SectionIdEnum.whyUs,
        component: <WhyUsSection/>
    },
    {
        sectionId: SectionIdEnum.quickNav,
        component: <QuickNavSection/>
    },
    {
        sectionId: SectionIdEnum.trainer,
        component: <TrainerSection/>
    },
    {
        sectionId: SectionIdEnum.contact,
        component: <ContactSection/>
    },
];

export const HomePage: React.FC = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <AppLayout isSmall={isSmall} navigation={<Navigation isSmall={isSmall}/>}>
            {sections.map(({component, sectionId}) => {
                return <SectionContainer sectionId={sectionId} key={sectionId}>
                    {component}
                </SectionContainer>;
            })}
        </AppLayout>
    );
}
