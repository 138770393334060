import React from "react";
import {SectionIdEnum} from "../../types";
import {Box} from "@mui/material";

export type SectionContainerProps = {
    children: React.ReactNode,
    sectionId: SectionIdEnum
}

export const SectionContainer: React.FC<SectionContainerProps> = ({sectionId, children}) => {
    return (
        <div id={sectionId} key={sectionId}>
                <Box minHeight={"40px"}>{children}</Box>
        </div>
    );
};
