import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import nl from "./translations/nl.json";

const resources = {
    nl: {
        translation: nl
    },
}

export const availableLanguages = Object.keys(resources)

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
            resources,
            fallbackLng: "nl",
            debug: true,

            interpolation: {
                escapeValue: false // not needed for react as it escapes by default
            }
        }
    );